let cdnjs = {
    "headroom": "https://cdn.jsdelivr.net/npm/headroom.js@0.9.4/dist/headroom.min.js",
    "touchswipe": "https://cdn.jsdelivr.net/npm/jquery-touchswipe@1.6.19/jquery.touchSwipe.min.js",
    "picturefill": "https://cdn.jsdelivr.net/npm/picturefill@3.0.3/dist/picturefill.min.js",
    "flickity": "https://cdn.jsdelivr.net/npm/flickity@2.1.2/dist/flickity.pkgd.min.js",
    "flickity_sync": "https://cdn.jsdelivr.net/npm/flickity-sync@2.0.0/flickity-sync.min.js",
    "fancybox": "https://cdn.jsdelivr.net/npm/@fancyapps/fancybox@3.5.2/dist/jquery.fancybox.min.js",
    "fancybox_css": "https://cdn.jsdelivr.net/npm/@fancyapps/fancybox@3.5.2/dist/jquery.fancybox.min.css",
    "rellax": "https://cdn.jsdelivr.net/npm/rellax@1.7.2/rellax.min.js",
    "datepicker": "https://cdnjs.cloudflare.com/ajax/libs/air-datepicker/2.2.3/js/datepicker.min.js",
    "datepicker_lang": "https://cdnjs.cloudflare.com/ajax/libs/air-datepicker/2.2.3/js/i18n/datepicker.cs.min.js",
    "googlemap": "https://maps.googleapis.com/maps/api/js?key=APIKEY",
    "recapcha": "https://www.google.com/recaptcha/api.js?render={apikey}",
    "parallax": "https://cdn.jsdelivr.net/npm/parallax-js@3.1.0/dist/parallax.min.js"
};
