(function($){
    const fn = $(".part_scroll_banner");

    if (fn.length) {
        if (body[0].style["transform"] === undefined) {
            fn.remove();
        }

        setTimeout(function() {
            cssLoaded(function () {
                if (fn.hasClass("is--force-show")) {
                    document.cookie = "scroll-banner=0; path=/; max-age=0";
                }

                fn.on("click", "[data-banner-open]", function() {
                    document.cookie = "scroll-banner=0; path=/; max-age=0";
                    fn.removeClass("state--active-lite");
                    setTimeout(function() {
                        fn.addClass("state--active");
                    },300);

                });

                fn.on("click", "[data-banner-close]", function() {
                    const el = $(this);
                    const type = el.data("banner-close");

                    if (type === "confirm") {
                        fn.find(".elm_banner_warning").addClass("state--active");
                    } else {
                        fn.find(".elm_banner_warning").removeClass("state--active");
                        setTimeout(function(){
                            fn.addClass("state--not-active").removeClass("state--active");
                            let expire = el.closest(".part_scroll_banner").data("banner-expire");
                            document.cookie = "scroll-banner=1; path=/; max-age=" + expire;
                            setTimeout(function(){
                                fn.removeClass("state--not-active").addClass("state--active-lite");
                            },1000);
                        },150);
                    }
                });

                if (document.cookie.indexOf('scroll-banner=1') === -1) {
                    let scroll_position = 50;
                    if (fn.filter("[data-scroll-position]").length) {
                        scroll_position = fn.data("scroll-position");
                    }

                    function fn_show_banner() {
                        if (document.cookie.indexOf('scroll-banner=1') === -1) {
                            if ((fn.offset().top + win.height() / 2) > (doc.height() / (100 / scroll_position)) && !fn.hasClass("state--not-active")) {
                                fn.addClass("state--active");
                            } else if ((doc.height() / win.height()) < 2) {
                                fn.addClass("state--active");
                            }
                        }
                    }

                    fn_show_banner();

                    win.on("scroll",fn_show_banner);
                } else {
                    fn.addClass("state--active-lite");
                }
            });
        },1000);
    }
})(jQuery);