$.lui("layout","#layout_header", function(selector) {
    if (!$(".ssm-overlay").length) {
        $.importScript(cdnjs.touchswipe, function() {
            $('.ssm-nav').lui_ssm();
        });
    }

    $.importScript(cdnjs.headroom, function(){
        let headroom = new Headroom(selector[0], {
            offset: 0,
            tolerance : {
                up : 5,
                down : 0
            },
            classes: {
                "initial" : "headroom",
                "pinned": "headroom--pinned",
                "unpinned": "headroom--unpinned",
                "top" : "headroom--top",
                "notTop" : "headroom--not-top"
            }
        });
        headroom.init();
    });

    doc.off("click.submenu");

    doc.on("click.submenu",`[data-action="toggleSub"]`,function (e) {
        e.preventDefault();

        let elm = $(this),
            submenu = elm.closest(`[data-ref="menuItem"]`).find(`[data-ref="submenu"]`);

        if(elm.hasClass("state--active")) {
            submenu.stop().slideUp();
            elm.removeClass("state--active");
        }
        else {
            submenu.stop().slideDown();
            elm.addClass("state--active");
        }
    })
});