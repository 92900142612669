(function($) {
    if (typeof Swup !== "undefined") {
        const swup = new Swup({
            elements: ['#layout_main', '#layout_header'],
            doScrollingRightAway: false,
            animateScroll: false
        });

        let timeout_swup;

        swup.on('clickLink', function (e) {
            let datepicker = $('#layout_main').find('[data-form-datepicker] input');
            datepicker.each(function(){
                let elm = $(this).datepicker().data('datepicker');
                if (typeof elm !== "undefined") {
                    elm.destroy();
                }
            });
            if (win.width() < 960 || window.document.location.pathname === $(e.target).attr("href")) {
                $(".ssm-nav-visible").removeClass("ssm-nav-visible");
            }
            $(".ssm-overlay").fadeOut(300);
            clearTimeout(timeout_swup);
            html.removeClass("ssm-active");
            timeout_swup = setTimeout(function(){
                html.removeClass('overflow--hidden');
            },1000);

            if ($(".lib--dialog").length) {
                nl_lib_dialog.close();
            }
        });

        swup.on('contentReplaced', function () {
            $(".ssm-nav-visible").removeClass("ssm-nav-visible");
            swup.options.elements.forEach((selector) => {
                Object.keys(window.lui.layout).forEach(function(key) {
                    if ($(key).length) {
                        window.lui.layout[key]("layout",key)
                    }
                });
                Object.keys(window.lui.comp).forEach(function(key) {
                    if ($(selector).find(key).length) {
                        window.lui.comp[key]("comp",key)
                    }
                });
                Object.keys(window.lui.part).forEach(function(key) {
                    if ($(selector).find(key).length) {
                        window.lui.part[key]("part",key)
                    }
                });
                Object.keys(window.lui.lib).forEach(function(key) {
                    let s = window.lui.lib[key].selector;
                    if ($(selector).find(s).length) {
                        window.lui.lib[key](key,$(selector).find(s))
                    }
                });
            });

            if (typeof fbq !== "undefined") {
                fbq('track', 'PageView');
            }

            if (typeof gtag !== "undefined") {
                let configs = [];
                window.dataLayer.forEach(function(config){
                    if (config[0] === "config") {
                        if (typeof config[1] !== "undefined" && !configs.includes(config[1])) {
                            configs.push(config[1]);
                            gtag('config', config[1], {
                                'page_title': document.title,
                                'page_path': window.location.pathname + window.location.search
                            });
                        }
                    }
                });
            }

            if (typeof window.dataLayer !== "undefined") {
                window.dataLayer.push({
                    'event': 'VirtualPageview',
                    'virtualPageURL': window.location.pathname + window.location.search,
                    'virtualPageTitle': document.title
                });
            }

            if (typeof window.seznam_retargeting_id !== "undefined") {
                $.getScript("https://c.imedia.cz/js/retargeting.js");
            }

            if(typeof window.luiCookieConsentInit !== 'undefined') {
                window.luiCookieConsentInit()
            }

            if (body.hasClass("body--preload")) {
                cssLoaded(function(){
                    body.removeClass("body--preload");
                    setTimeout(function () {
                        body.addClass("body--loaded");
                    }, 300);
                });
            }
        });
    }

    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().lui_parallax();
        }
    });

    lui_dialog.init(function(){
        Object.keys(window.lui.lib).forEach(function(key) {
            let s = window.lui.lib[key].selector;

            if (dialog_selector.find(s).length) {
                window.lui.lib[key](key,$(".lib--dialog").find(s))
            }
        });

        Object.keys(window.lui.part).forEach(function(key) {
            if ($(`.lib--dialog ${key}`).length) {
                window.lui.part[key]("part",`.lib--dialog ${key}`)
            }
        });
    });

    $.lui("lib_parallax", ".lib--parallax", function(selector) {
        cssLoaded(function(){
            selector.find("img:not(.lazyload)").parent().lui_parallax();
        });
    });

    $.lui("lib_reveal", "[data-lib-reveal]", function() {
        $.lib_reveal();
    });

    $.lui("lib_visual_scroll", "[data-visual-scroll]", function(selector) {

        selector.on("click", function(){
            $('html, body').animate({
                scrollTop: selector.closest(`[class^="comp_"]`).next().offset().top - 102
            }, 1000);
        });

    });

    $.lui("lib_ripple", ".part_ui_link, .part_ui_btn, .part_ui_checkbox, .part_ui_radio", function(selector) {
        selector.lui_ripple();
    });

    $.lui("lib_flickity", "[data-lib-flickity]", function(selector) {
        $.lib_flickity(function(){
            selector.each(function(){
                let elm = $(this);
                let options = elm.data("lib-flickity");
                let nav = elm.parent().find("[data-lib-flickity-nav]");

                let slider = $(this).flickity({
                    groupCells: '25%',
                    cellAlign: 'left',
                    setGallerySize: true,
                    autoPlay: options["autoplay"],
                    pageDots: true,
                    wrapAround: (typeof options["wrapAround"] !== "undefined") ? options["wrapAround"] : false,
                    contain: true,
                    pauseAutoPlayOnHover: true,
                    prevNextButtons: true
                });

                slider.on('dragStart.flickity', () => slider.find('.flickity-slider')[0].childNodes.forEach(slide => slide.style.pointerEvents = "none") );
                slider.on('dragEnd.flickity', () => slider.find('.flickity-slider')[0].childNodes.forEach(slide => slide.style.pointerEvents = null) );

                if (nav.length) {
                    nav.on("click", function(){
                        let direction = $(this).data("lib-flickity-nav");

                        if (direction === "prev") {
                            slider.flickity('previous');
                        } else {
                            slider.flickity('next');
                        }
                    });
                }
            });
        });
    });

    $.lui("lib_gallery", "[data-lib-fancybox]", function(selector) {
        bodyLoaded(function(){
            if ($(selector).find(".lib--fancybox-item").length) {
                $.importStyle(cdnjs.fancybox_css);
                $.importScript(cdnjs.fancybox, function(){
                    $(selector).each(function(){
                        let id = $(this).data("lib-fancybox");
                        $(this).fancybox({
                            selector: `[data-lib-fancybox="${id}"] .lib--fancybox-item`,
                            transitionEffect: "slide",
                            animationEffect: "zoom",
                            hideScrollbar: false,
                            buttons: [
                                "close"
                            ]
                        });
                    });
                });
            }
        });
    });

    $.lui("lib_rellax", "[data-lib-rellax]", function(selector) {
        if ($(selector).length && win.width() > 960) {
            $.importScript(cdnjs.rellax, function() {
                bodyLoaded(function(){
                    selector.each(function(){
                        new Rellax($(this)[0], {
                            center: $(this).data("lib-rellax") === "center"
                        });
                    });
                });
            });
        }
    });

    $.lui("lib_parallax_scene", "[data-lib-parallax-scene]", function(selector) {
        if ($(selector).length && win.width() > 960) {
            $.importScript(cdnjs.parallax, function() {
                bodyLoaded(function(){
                    selector.each(function(){
                        new Parallax($(this)[0],{
                            hoverOnly: true,
                            clipRelativeInput: true
                        });
                    });
                });
            });
        }
    });

    $.lui("lib_reCapcha", "[data-recaptcha]", function(selector) {
        $(selector).lib_reCapcha($(selector).data('recaptcha'));
    });

    (function fn_ajaxEvents() {
        doc.on('click', '[href].ajax', function (e) {
            e.preventDefault();
            let $el = $(this);

            $.ajax({
                url: $el.attr('href'),
                data: {ajax: +new Date},
                dataType: "json",
                success: function (payload) {
                    fn_ajaxHandler(payload);
                }
            });
        });

        doc.on('submit', 'form.ajax,form[data-component-ajax]', function (e) {
            e.preventDefault();
            let frm = $(this),
                formData = new FormData($(this)[0]);

            $.fn.validateInput = function () {
                $(this).removeClass("state--invalid state--valid");
                if ($(this).find("input, textarea").is(":valid")) {
                    $(this).addClass("state--valid");
                } else {
                    $(this).addClass("state--invalid");
                }
            };

            frm.find(".part_ui_input").each(function () {
                if (!$(this).filter("[data-no-validation]").length && !$(this).hasClass("state--invalid")) {
                    $(this).validateInput();
                }
            });

            var submitButton = $(this).find('button[type="submit"]');
            if(submitButton.length) {
                submitButton.attr('disabled','disabled');
                submitButton.addClass('mod--loading');
            }

            $.ajax({
                method: frm.attr('method'),
                url: frm.attr('action'),
                data: formData,
                processData: false,
                contentType: false,
                dataType: "json",
                success: function (payload) {
                    fn_ajaxHandler(payload, function(){
                        if(submitButton.length) {
                            setTimeout(function() {
                                submitButton.removeAttr('disabled');
                                submitButton.removeClass('mod--loading');
                            },3000);
                        }
                    });
                }
            });
        });

        doc.on('click', 'a[data-component-ajax]', function (e) {
            e.preventDefault();
            let elm = $(this);
            let url = $(this).attr('data-href');
            if (!url || !url.length) {
                url = $(this).attr('href');
            }

            if (!url || !url.length) {
                console.log('no href attribute for ajax component link');
                return;
            }

            $('[data-component-ajax]').removeClass('state--active');

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    elm.addClass('state--active');
                    ScrollReveal().sync();
                });
            });
        });
    })();

    (function fn_pictureFill() {
        let picture = document.createElement('picture'),
            strObj = '' + picture;

        if(strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie"))
        {
            $.importScript(cdnjs.picturefill);
        }
    })();

    (function fn_gridFill() {
        if (typeof html[0].style.grid !== 'string') {
            html.addClass("no-grid");
        }
    })();
})(jQuery);
